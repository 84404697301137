<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card class="mb-4">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            class="mb-2 ml-3 mt-3"
            @click="NewProduct"
          >
            Nuevo Producto
          </v-btn>
          <v-subheader>Selecciona un producto para editar sus caracteristicas</v-subheader>

        </v-card>
            <v-card v-for="product in Products" :key="product" class="mb-4">
          <template>
            <v-card class="mx-auto" max-width="100%">
              <v-card-title> {{product.name}} </v-card-title>
              
              <v-card-text>
                <div v-html="product.description"></div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue lighten-2" text @click="editProduct(product.id)"> Ver más </v-btn>
              </v-card-actions>
            </v-card>
          </template>
          </v-card>
        </v-col
      ></v-row>
  </div>
</template>
     
<script>
export default {
  data(){
      return{
            show: false,
            id : this.$route.params.id,
            Products:{}
      }
    
  },
  methods: {
      getProducts(){
          this.$http.get('products/architecture/'+this.id).then(response => {
            this.Products = response.data
            }).catch(error => {
                console.error(error)
            })
      },
      NewProduct(){
        this.$router.push({ name: 'NewProduct', params:{ id: this.id}});
      },
      editProduct(id){
        this.$router.push({ name: 'EditProduct', params:{ id: id}});
      }
  },

  beforeMount() {
      console.log(this.$route.params.id,)
      this.getProducts()
  },
};
</script>
<style scoped>
.grid_cards{
    display: grid;
    grid-template-columns: repeat(3,1fr);
}
</style>